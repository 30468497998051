import React, { useEffect, useState, useRef } from "react";
import ModStazioni from "./ModStazioni";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

function Stazioni(props){
    const [isMobile, setIsMobile] = useState(props.isMobile);
    const scroll = useRef();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [stations, setStations] = useState([]);
    const [change_page, setChange_page] = useState(false);
    const [exportData, setExportData] = useState();
    const [showSearch, setShowSearch] = useState(false);
    let settings = {
        "pagination": {"pageSize": 10},
        "query": { name: { $regex: "", $options: "i" } }
      };
    let page = 0;
    let isBottom = false;

    function fetch_data(){
        settings["pagination"]["pageIndex"] = page;
        const new_data = [];

        fetch(`${API_URL}/api/station/query`, {
            method: "POST",
            body: JSON.stringify(settings),
            headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
        }).then((res) => {
            if(res.ok){
                return res.json();
            } else if (res.status === 400) {
                toast.error("Formato richiesta errato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 401) {
                toast.error("Sessione non valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                navigate("/login")
                localStorage.removeItem("token");
                return Promise.reject(res);
            } else if (res.status === 403) {
                toast.error("Non disponi delle autorizzazioni necessarie", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 404) {
                toast.error("Risorsa non trovata", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 500) {
                toast.error("Errore interno server", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            }
        })
        .then(({docs}) => {
            docs.forEach((i) => new_data.push(i));
            if (new_data.length !== 0) {
                setStations((stations) => [...stations, ...new_data]);
            } else {
                isBottom = true;
            }
        }).catch((e) => console.log(e))
    }

    useEffect (() => {
        if(!token){
            navigate("/login")
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleResize);
        
        if (page === 0) {
            fetch_data();
        }

        scroll.current.addEventListener("scroll", () => {
            if ((scroll.current.scrollHeight - scroll.current.offsetHeight) < scroll.current.scrollTop+10) {
                if (!isBottom){
                    page += 1;
                    fetch_data();
                }
            }
        })
    }, [])

    function search(filter){
        if (filter !=='' ) {
            settings["query"]["name"]["$regex"] = filter
        };
        setStations([]);
        fetch_data();
    }

    function infoClick(export_id){
        if (export_id === "New"){
            setExportData(export_id);
        } else {
            setExportData(stations[stations.map(data_export => data_export._id).indexOf(export_id)])
        }
        setChange_page(true);
    }

    if (!change_page) {
        if (!isMobile){
            return(
                <div className="right_block">
                    <div className="flex">
                        <div style={{"width": "calc( 100% - 28rem)"}}>
                            <p className="titles">Stazioni</p>
                            <input type="text" id="search" placeholder="Cerca stazione..." className="search_bar" spellCheck="false" onChange={filter => search(filter.target.value)}/>
                        </div>
                        <button className="nuova_stazione" onClick={() => infoClick("New")}>Nuova stazione</button>
                    </div>
                    <div className="space"/>
                    <div className="lista_stazioni" ref={scroll}>
                        {Object.values(stations).map((data) => (
                            <div className="elementi_lista_stazioni" key={data._id}>
                                <div className="left-block_lista">
                                    <div className="testo" style={{"marginTop": "0rem"}}>{data.name}</div>
                                    <div className="testo" style={{"fontSize": "1.2rem", "marginTop": "0rem"}}>{data.address}</div>
                                </div>
                                <div className="right-block_lista">
                                    <div className="caricatori">
                                        <div style={{"marginTop": "0.2rem"}}>Caricatori</div>
                                        <div style={{"fontSize": "2.8rem", "color": "#146A94", "fontWeight": "bold"}}>{data.chargePointNumber}</div>
                                    </div>
                                    <button className="info_image" onClick={() => infoClick(data["_id"])}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        } else {
            return(
                <div className="bottom_block_mobile">
                        {!showSearch ?
                            <div className="flex_mobile">
                                <p className="testo_mobile">Stazioni</p>
                                <div>
                                    <button className="search_mobile" onClick={() => setShowSearch(!showSearch)}/>
                                    <button className="nuova_stazione_mobile" onClick={() => infoClick("New")}/>
                                </div>
                            </div>
                            : <div className="flex_mobile">
                                <input type="text" id="search" placeholder="Cerca stazione..." className="search_bar" spellCheck="false" onChange={filter => search(filter.target.value)}/>
                                <button className="search_mobile" onClick={() => setShowSearch(!showSearch)}/>
                            </div>
                        }
                    <div className="space"/>
                    <div className="lista_stazioni" style={{"height": "calc(100% - 5rem)"}} ref={scroll}>
                        {Object.values(stations).map((data) => (
                            <div className="elementi_lista_stazioni_mobile" key={data._id}>
                                <div className="testo_mobile" style={{"marginTop": "0rem", "fontSize": "1.5rem"}}>{data.name}</div> 
                                <div className="testo_mobile" style={{"fontSize": "1rem", "marginTop": "0rem"}}>{data.address}</div>
                                <div className="bottom_lista_mobile">
                                    <div style={{"fontSize": "2.8rem", "marginLeft": "0.2rem", "color": "#146A94", "fontWeight": "bold"}}>{data.chargePointNumber}</div>
                                    <div style={{"marginTop": "1.7rem", "marginLeft": "0.2rem"}}>Caricatori</div>
                                    <div style={{"width": "calc(100% - 10rem)"}}/>
                                    <div style={{"backgroundColor": "#146A94", "marginTop": "1rem", "width": "3rem", "borderRadius": "1rem", "alignItems": "center"}}>
                                        <button className="info_image_mobile" style={{"float": "left"}} onClick={() => infoClick(data["_id"])}/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    } else {
        return(<ModStazioni itemsData = {exportData} isMobile = {isMobile}/>)
    }
}

export default Stazioni;