import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModCaricatori from "./ModCaricatori";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

function RenderStazioni(props){
    const [isMobile, setIsMobile] = useState(props.isMobile);
    const [exportData, setExportData] = useState({});
    const navigate = useNavigate();
    const settings = { "pagination": {"pageSize": 100}, "query": {"station": props.idStation} };
    const [chargers, setChargers] = useState({});
    const token = localStorage.getItem('token');
    const [view, setView] = useState(0);
    const [changeView, setChangeView] = useState(false);

    function fetch_chargers(){
        fetch(`${API_URL}/api/station-charger/query`, {
            method: "POST",
            body: JSON.stringify(settings),
            headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
        }).then((res) => {
            if(res.ok){
                return res.json();
            } else if (res.status === 400) {
                toast.error("Formato richiesta errato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 401) {
                toast.error("Sessione non valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                navigate("/login")
                localStorage.removeItem("token");
                return Promise.reject(res);
            } else if (res.status === 403) {
                toast.error("Non disponi delle autorizzazioni necessarie", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 404) {
                toast.error("Risorsa non trovata", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 500) {
                toast.error("Errore interno server", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            }
        })
        .then(({docs}) => {
            setChargers(docs);
            if (Object.keys(docs).length !== 0){
                setView(1)
            }
        }).catch((e) => console.log(e))
    }

    useEffect (() => {
        if(!token){
            navigate("/login")
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleResize);

        if (props.dataStation !== "New"){
            fetch_chargers();
        }
    }, [])

    useEffect(() => {
        if (props.changeView || changeView) {
            fetch_chargers();
        }
    }, [props.changeView, changeView])

    function infoClick(info){
        setExportData(info);
        setView(2);
    }

    function formatNumber(number){
        const n_len = number.toFixed(0).toString().length;
        if (n_len <= 3){
            return(number.toFixed(3-n_len))
        } else {
            return("NaN")
        }
    }

    function isAvailable(status){
        if (status === "Available"){
            return(
                !isMobile ?
                    <img className="status_type" alt="green" src="/assets/images/green.png"/>
                    : <img className="status_type_mobile" alt="green" src="/assets/images/green.png"/>
            )
        } else {
            return(
                !isMobile ?
                    <img className="status_type" alt="green" src="/assets/images/red.png"/>
                    : <img className="status_type_mobile" alt="green" src="/assets/images/red.png"/>
            )
        }
    }

    function plugtype(type){
        if (type === "Type 1"){
            return(
                !isMobile ?
                    <img className="status_type" alt="type_1" src="/assets/images/type1.png"/>
                    : <img className="status_type_mobile" alt="type_1" src="/assets/images/type1.png"/>
            )
        } else if (type === "Type 2"){
            return(
                !isMobile ?
                    <img className="status_type" alt="type_1" src="/assets/images/type2.png"/>
                    : <img className="status_type_mobile" alt="type_1" src="/assets/images/type2.png"/>
            )
        } else if (type === "CCS"){
            return(
                !isMobile ?
                    <img className="status_type" alt="type_1" src="/assets/images/ccs.png"/>
                    : <img className="status_type_mobile" alt="type_1" src="/assets/images/ccs.png"/>
            )
        } else if (type === "CHAdeMO"){
            return(
                !isMobile ?
                    <img className="status_type" alt="type_1" src="/assets/images/chademo.png"/>
                    : <img className="status_type_mobile" alt="type_1" src="/assets/images/chademo.png"/>
            )
        }
    }

    if (view === 0){
        return(<div className="messaggio">Non ci sono ancora Charge Points</div>)
    } else if (view === 1){
        if (!isMobile){
            return(
                <div className="lista_stazioni" style={{"height": "calc(100% - 21.5rem)"}}>
                    {Object.values(chargers).map((data) => (
                        <div className="elementi_lista_stazioni" key={data._id}>
                            <div className="left-block_lista" style={{"width": "calc(100% - 32rem)"}}>
                                <div className="testo">{data.code}</div>
                            </div>
                            <div className="right-block_lista" style={{"width": "30rem"}}>
                                <div className="caricatori" style={{"paddingRight": "1rem", "width": "6rem"}}>
                                    <div style={{"paddingTop": "0.2rem", "fontSize": "1.2rem"}}>{data.plugType}</div>
                                    {plugtype(data.plugType)}
                                </div>
                                <div className="caricatori" style={{"paddingRight": "1rem"}}>
                                    <div style={{"paddingTop": "0.2rem", "fontSize": "1.2rem"}}>Disponibile</div>
                                    {isAvailable(data.status)}
                                </div>
                                <div className="caricatori">
                                    <div style={{"paddingTop": "0.2rem"}}>Costo</div>
                                    <div style={{"display": "flex"}}>
                                        <div style={{"fontSize": "2rem", "paddingTop": "0.5rem", "color": "#146A94", "fontWeight": "bold", "width": "5rem"}}>€{formatNumber(data.cost)}</div>
                                        <div style={{"fontSize": "1rem", "paddingTop": "1.4rem", "paddingLeft": "1.2rem", "color": "#146A94", "fontWeight": "bold"}}>/kWh</div>
                                    </div>
                                </div>
                                <button className="info_image" onClick={() => infoClick(data)}/>
                            </div>
                        </div>
                    ))}
                </div>
            )
        } else {
            return(
                <div className="lista_stazioni" style={{"height": "calc(100% - 21.5rem)"}}>
                    {Object.values(chargers).map((data) => (
                        <div className="elementi_lista_stazioni_mobile" style={{"height": "8rem"}} key={data._id}>
                            <div className="flex_mobile">
                                <div className="testo_mobile" style={{"marginTop": "0rem"}}>{data.code}</div>
                                <div className="flex_mobile">
                                    <div style={{"paddingTop": "0.4rem", "fontSize": "1.2rem"}}>Disponibile</div>
                                    {isAvailable(data.status)}
                                </div>
                            </div>
                            <div className="flex_mobile">
                                {plugtype(data.plugType)}
                                <div style={{"paddingTop": "0.4rem", "fontSize": "1.2rem", "marginRight": "calc(100% - 9rem)"}}>{data.plugType}</div>
                            </div>
                            <div className="flex_mobile" style={{"height": "2rem"}}>
                                <div style={{"display": "flex"}}>
                                    <div style={{"fontSize": "2rem", "color": "#146A94", "fontWeight": "bold", "width": "5rem"}}>€{formatNumber(data.cost)}</div>
                                    <div style={{"fontSize": "0.8rem", "paddingTop": "1.1rem", "paddingLeft": "1.2rem", "color": "#146A94", "fontWeight": "bold"}}>/kWh</div>
                                </div>
                                <div style={{"backgroundColor": "#146A94", "width": "3rem", "height": "2rem", "borderRadius": "1rem", "alignItems": "center"}}>
                                    <button className="info_image_mobile" style={{"float": "left"}} onClick={() => infoClick(data)}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    } else if (view === 2){
        return(<ModCaricatori chargerData={exportData} dataStation={props.dataStation} isMobile = {isMobile} idStation={props.idStation} changeView={() => {setChangeView(true)}}/>)
    }
}

export default RenderStazioni;