import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RenderPagina from "./components/RenderPagina";
import './Dashboard.css';

function Dashboard() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [pagina, setPagina] = useState(0);
    const pagine = {"Stazioni":0, "Sessioni":1, "Transazioni":2};
    const [colour_button, setColour_button] = useState(["#C93A0E", "#E44311", "#E44311"]);
    const [choosePage, setChoosePage] = useState(false);

    useEffect(() => {
        if(!token){
            if(navigator.userAgent.match(/Android/i)){
                window.location = "https://play.google.com/store/apps/details?id=it.overzoom.orange.orange_charge";
            }
            else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)){
                window.location = "https://apps.apple.com/it/app/orange-charge/id1641674171";
            }
            else {
                navigate("/login");
            }
        }

        setColour_button(["#E44311", "#E44311", "#E44311"]);
        setColour_button({ ...["#E44311", "#E44311", "#E44311"], [pagina]: "#C93A0E" });
    }, [pagina])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleResize);
    }, [])

    const handleClick = target => {
        setChoosePage(false);
        setPagina(pagine[target]);
    };

    function logout(){
        localStorage.removeItem('token');
        navigate("/login")
    }

    function ChoosePage(){
        return(
            <div className="bottom_block_mobile" style={{"backgroundColor": "#E44311"}}>
                <button className="bottone_laterale petrol_station" style={{"backgroundColor": colour_button[0]}} onClick={() => handleClick("Stazioni")}>Stazioni</button>
                <div className="space" />
                <button className="bottone_laterale sessions" style={{"backgroundColor": colour_button[1]}} onClick={() => handleClick("Sessioni")}>Sessioni</button>
                <div className="space" />
                <button className="bottone_laterale transactions" style={{"backgroundColor": colour_button[2]}} onClick={() => handleClick("Transazioni")}>Transazioni</button>
                <div style={{"height": "calc(100% - 22rem)"}}/>
                <div className="flex">
                    <div className="flex">
                        <div className="admin"/>
                        <div style={{"fontSize": "2rem", "paddingTop": "0.8rem"}}>Admin</div>
                    </div>
                    <button className="exit" style={{"fontSize": "2rem"}} onClick={() => logout()}/>
                </div>
            </div>
        )
    }

    if (!isMobile){
        return(
            <div className="container">
                <div className="left_block">
                    <img src="/assets/images/logo.png" alt="Logo" className="logo_dashboard"/>
                    <button className="bottone_laterale petrol_station" style={{"backgroundColor": colour_button[0]}} onClick={() => handleClick("Stazioni")}>Stazioni</button>
                    <div className="space" />
                    <button className="bottone_laterale sessions" style={{"backgroundColor": colour_button[1]}} onClick={() => handleClick("Sessioni")}>Sessioni</button>
                    <div className="space" />
                    <button className="bottone_laterale transactions" style={{"backgroundColor": colour_button[2]}} onClick={() => handleClick("Transazioni")}>Transazioni</button>
                    <div style={{"height": "calc(100% - 30rem)"}}/>
                    <div className="flex">
                        <div className="admin"/>
                        <div style={{"fontSize": "2rem", "paddingTop": "0.8rem"}}>Admin</div>
                        <button className="exit" style={{"fontSize": "2rem"}} onClick={() => logout()}/>
                    </div>
                </div>
                <RenderPagina pagina = {pagina}/>
            </div>
        );
    } else {
        return(
            <div className="container">
                <div className="up_block">
                    <button className="menu_image" onClick={() => {setChoosePage(!choosePage)}}/>
                    <div className="logo_dashboard_mobile"/>
                </div>
                {!choosePage ? <RenderPagina pagina = {pagina} isMobile = {isMobile}/> : <ChoosePage/>}
            </div>
        );
    }
}

export default Dashboard;
