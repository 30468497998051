import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stazioni from "./Stazioni";
import RenderStazioni from "./RenderStazioni";
import ModCaricatori from "./ModCaricatori";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";

import MapPicker from 'react-google-map-picker';

const DefaultLocation = { lat: 41.240797, lng: 16.309965};
const DefaultZoom = 14;
const API_URL = process.env.REACT_APP_API_URL;

function ModStazioni(props){
    const [isMobile, setIsMobile] = useState(props.isMobile);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [change_page, setChange_page] = useState(false);
    const [add, setAdd] = useState(false);
    const [isNew, setIsNew] = useState(props.itemsData);
    const [changeView, setChangeView] = useState(false);
    const [fields, setFields] = useState({
        "name": props.itemsData["name"] === undefined ? "" : props.itemsData["name"],
        "address": props.itemsData["address"] === undefined ? "" : props.itemsData["address"],
        "stationVendor": props.itemsData["stationVendor"] === undefined ? "" : props.itemsData["stationVendor"],
        "chargePointNumber": 0
        });
    const [idStation, setIdStation] = useState(props.itemsData["_id"]);

    const [defaultLocation, setDefaultLocation] = useState(() => {
        if (isNew !== "New") {
            return {
                lat: props.itemsData["location"]["coordinates"][0],
                lng: props.itemsData["location"]["coordinates"][1]
            };
    } else {
            return DefaultLocation
        }
    });
    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation (lat, lng){
        setLocation({lat:lat, lng:lng});
        // setDefaultLocation({lat:lat, lng:lng})
    }

    function handleChangeZoom (newZoom){
        setZoom(newZoom);
    }

    useEffect (() => {
        if(!token){
            navigate("/login")
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleResize);
    }, [])

    function backButton(){
        setChange_page(true)
    }

    function clickSave(is_new){
        if (!fields_controller()){
            if (isNew === "New"){
                fetch(`${API_URL}/api/station`, {
                    method: "POST",
                    body: JSON.stringify({
                        ...fields,
                        "location": {
                            "type": "Point",
                            "coordinates": [location.lat, location.lng]
                        }}),
                    headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    } else if (res.status === 400) {
                        toast.error("Formato richiesta errato", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 401) {
                        toast.error("Sessione non valida", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        navigate("/login")
                        localStorage.removeItem("token");
                        return Promise.reject(res);
                    } else if (res.status === 403) {
                        toast.error("Non disponi delle autorizzazioni necessarie", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 404) {
                        toast.error("Risorsa non trovata", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 500) {
                        toast.error("Errore interno server", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    }
                })
                .then((res) => {
                    console.log(res);
                    setIdStation(res._id);
                    if (!is_new){
                        setChange_page(true);
                    }
                }).catch((e) => console.log(e))
            } else {
                console.log(fields)
                fetch(`${API_URL}/api/station/${idStation}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        ...fields,
                        "location": {
                            "type": "Point",
                            "coordinates": [location.lat, location.lng]
                        }}),
                    headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    } else if (res.status === 400) {
                        toast.error("Formato richiesta errato", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 401) {
                        toast.error("Sessione non valida", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        navigate("/login")
                        localStorage.removeItem("token");
                        return Promise.reject(res);
                    } else if (res.status === 403) {
                        toast.error("Non disponi delle autorizzazioni necessarie", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 404) {
                        toast.error("Risorsa non trovata", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 500) {
                        toast.error("Errore interno server", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    }
                })
                .then((res) => {
                    console.log(res);
                    setChange_page(true);
                }).catch((e) => console.log(e))
            }
        } else {
            toast.error("Completa tutti i campi", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 50,
            });
        }
    }

    function fields_controller(){
        if (Object.values(fields).includes("")){
            return(true)
        }
    }

    function remove_chargers(){
        fetch(`${API_URL}/api/station-charger/delete-many`, {
            method: "POST",
            body: JSON.stringify({"query": {"station": idStation}}),
            headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
        }).then((res) => {
            if(res.ok){
                return res.json();
            } else if (res.status === 400) {
                toast.error("Formato richiesta errato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 401) {
                toast.error("Sessione non valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                navigate("/login")
                localStorage.removeItem("token");
                return Promise.reject(res);
            } else if (res.status === 403) {
                toast.error("Non disponi delle autorizzazioni necessarie", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 404) {
                toast.error("Risorsa non trovata", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 500) {
                toast.error("Errore interno server", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            }
        }).catch((e) => console.log(e))
    }

    function clickDelete(){
        remove_chargers();
        fetch(`${API_URL}/api/station/${idStation}`, {
            method: "DELETE",
            headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
        }).then((res) => {
            if(res.ok){
                return res.json();
            } else if (res.status === 400) {
                toast.error("Formato richiesta errato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 401) {
                toast.error("Sessione non valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                navigate("/login")
                localStorage.removeItem("token");
                return Promise.reject(res);
            } else if (res.status === 403) {
                toast.error("Non disponi delle autorizzazioni necessarie", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 404) {
                toast.error("Risorsa non trovata", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 500) {
                toast.error("Errore interno server", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            }
        })
        .then((res) => {
            console.log(res);
            setChange_page(true);
        }).catch((e) => console.log(e))
    }

    function fields_catch(data_fields){
        const value = data_fields.target.value;
        const name = data_fields.target.id;
        setFields({ ...fields, [name]: value });
    }

    function show_del_button(){
        if (isNew !== "New"){
            if (!isMobile){
                return(<button className="delete" onClick={() => clickDelete()}/>)
            } else {
                return(<button className="delete_mobile" onClick={() => clickDelete()}/>)
            }
        }
    }

    function addClick() {
        setAdd(true)
    }

    function popup_function(){
        return(!isMobile ?
            <Popup
                trigger={<button className="aggiungi">Aggiungi</button>}
                modal
                nested
            >
            {close => (
                <div className="popup">
                    <div className="popup_title">AVVISO</div>
                    <div className="testo">
                        Per aggiungere un chargerpoint alla stazione,
                        bisogna prima salvarla.
                    </div>
                    <div className="testo">Salvare ora la stazione?</div>
                    <div className="popup_actions">
                        <div style={{"width": "14rem"}}/>
                        <button
                            className="popup_button"
                            style={{"backgroundColor": "#C93F10"}}
                            onClick={() => {
                                close();
                            }}
                            >
                            ANNULLA
                        </button>
                        <button
                            className="popup_button"
                            style={{"backgroundColor": "#146A94"}}
                            onClick={() => {
                                if (!fields_controller()){
                                    setIsNew("False");
                                    clickSave(true);
                                    addClick();
                                    close();
                                } else {
                                    toast.error("Completa tutti i campi", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 50,
                                    });
                                    close();
                                }
                            }}
                            >
                            SALVA
                        </button>
                    </div>
                </div>
            )}
            </Popup>
            : <Popup
                trigger={<button className="aggiungi_mobile"/>}
                modal
                nested
            >
            {close => (
                <div className="popup_mobile">
                <div className="popup_title">AVVISO</div>
                <div className="testo_mobile" style={{"color": "black", "textAlign": "center"}}>
                    Per aggiungere un chargerpoint alla stazione,
                    bisogna prima salvarla.
                </div>
                <div className="testo_mobile" style={{"color": "black", "textAlign": "center"}}>Salvare ora la stazione?</div>
                    <button
                        className="popup_button"
                        style={{"backgroundColor": "#146A94", "fontSize": "1.5rem", "width": "calc(100% - 2.2rem)"}}
                        onClick={() => {
                            if (!fields_controller()){
                                setIsNew("False");
                                clickSave(true);
                                addClick();
                                close();
                            } else {
                                toast.error("Completa tutti i campi", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 50,
                                });
                                close();
                            }
                        }}
                        >
                        SALVA
                    </button>
                    <button
                        className="popup_button"
                        style={{"backgroundColor": "#C93F10", "fontSize": "1.5rem", "width": "calc(100% - 2.2rem)", "marginTop": "0.5rem", "paddingBottom": "0.5rem"}}
                        onClick={() => {
                            close();
                        }}
                        >
                        ANNULLA
                    </button>
                </div>
            )}
            </Popup>
        )
    }

    if (!change_page) {
        if (!isMobile){
            return(
                <div className="right_block">
                    <div>
                        <button className="back" onClick={() => backButton()}>Indietro</button>
                    </div>
                    <div className="flex">
                        <input type="text" id="name" placeholder="Nome stazione" value={fields.name} className="field_mod" spellCheck="false" onChange={data => fields_catch(data)}/>
                        {show_del_button()}
                        <button className="salva" onClick={() => clickSave()}>Salva</button>
                    </div>
                    <div className="testo" style={{"fontSize": "2rem"}}>Generali</div>
                    <div className="flex">
                        <div>
                            <div className="testo" style={{"fontSize": "1.4rem"}}>Indirizzo</div>
                            <input type="text" id="address"  value={fields.address} className="fields_generali" style={{"float": "left"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                        </div>
                        <div>
                            <div className="testo" style={{"fontSize": "1.4rem"}}>Proprietario</div>
                            <input type="text" id="stationVendor"  value={fields.stationVendor} className="fields_generali" style={{"float": "right"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                        </div>
                    </div>
                    <div className="testo" style={{"fontSize": "1.4rem", 'marginTop': "40px"}}>Coordinate</div>
                    <div className="flex" style={{height:'200px'}}>
                        <MapPicker value={fields.coordinates}
                                   className="fields_generali"
                                   defaultLocation={defaultLocation}
                                   zoom={zoom}
                                   mapTypeId="roadmap"
                                   style={{height:'200px',borderRadius:'2rem',"z-index": "3"}}
                                   onChangeLocation={(lat, lng) => handleChangeLocation(lat, lng)}
                                   onChangeZoom={handleChangeZoom}
                                   apiKey='AIzaSyCxHC_SROYVgHhQJ5zX7qQfNt5Fai6c5AU'/>
                    </div>
                    <div className="flex">
                        <div className="testo" style={{"fontSize": "2rem"}}>Charge Points</div>
                        {
                            isNew==="New" ? popup_function()
                            : <button className="aggiungi" onClick={() => addClick()}>Aggiungi</button> 
                        }
                    </div>
                    <div className="space"/>
                    {
                        !add ? <RenderStazioni dataStation={props.itemsData} idStation={idStation} changeView={changeView}/>
                        : <ModCaricatori chargerData="New" dataStation={props.itemsData} idStation={idStation} onSubmit={() => {setAdd(!add)}} changeView={() => {setChangeView(true)}} />
                    }
                </div>
            )
        } else {
            return(
                <div className="bottom_block_mobile">
                    <div className="flex_mobile">
                        <button className="back" onClick={() => backButton()}>Indietro</button>
                        <div style={{"width": "calc(100% - 16rem)"}}/>
                        {show_del_button()}
                        <button className="salva_mobile" onClick={() => clickSave()}/>
                    </div>
                    <input type="text" id="name" placeholder="Nome stazione" value={fields.name} className="field_mobile" spellCheck="false" onChange={data => fields_catch(data)}/>
                    <div className="testo_mobile" style={{"fontSize": "1.5rem", "paddingLeft": "0.5rem"}}>Generali</div>
                    <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem"}}>Indirizzo</div>
                    <input type="text" id="address"  value={fields.address} className="field_mobile" spellCheck="false" onChange={data => fields_catch(data)}/>
                    <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem"}}>Proprietario</div>
                    <input type="text" id="stationVendor"  value={fields.stationVendor} className="field_mobile" spellCheck="false" onChange={data => fields_catch(data)}/>
                    <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem"}}>Coordinate</div>
                    <div className="flex" style={{height:'150px'}}>
                        <MapPicker value={fields.coordinates}
                                   className="field_mobile"
                                   defaultLocation={defaultLocation}
                                   zoom={zoom}
                                   mapTypeId="roadmap"
                                   style={{height:'150px',borderRadius:'2rem'}}
                                   onChangeLocation={(lat, lng) => handleChangeLocation(lat, lng)}
                                   onChangeZoom={handleChangeZoom}
                                   apiKey='AIzaSyCxHC_SROYVgHhQJ5zX7qQfNt5Fai6c5AU'/>
                    </div>
                    <div className="flex_mobile">
                        <div className="testo_mobile" style={{"fontSize": "1.5rem", "paddingLeft": "0.5rem"}}>Charge Points</div>
                        {
                            isNew==="New" ? popup_function()
                            : <button className="aggiungi_mobile" onClick={() => addClick()}/> 
                        }
                    </div>
                    {
                        !add ? <RenderStazioni dataStation={props.itemsData} idStation={idStation} changeView={changeView} isMobile = {isMobile}/>
                        : <ModCaricatori chargerData="New" dataStation={props.itemsData} idStation={idStation} isMobile = {isMobile} onSubmit={() => {setAdd(!add)}} changeView={() => {setChangeView(true)}} />
                    }
                </div>
            )
        }
    } else {
        return(<Stazioni isMobile = {isMobile}/>)
    }
}

export default ModStazioni;