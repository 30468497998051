import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

function Sessioni(props){
    const [isMobile, setIsMobile] = useState(props.isMobile);
    const scroll = useRef();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [sessions, setSessions] = useState([]);
    let settings = {
        "pagination": {"pageSize": 10},
        "query": {}
      };
    const [page_mobile, setPage_mobile] = useState(0);
    const [isBottom_mobile, setIsBottom_mobile] = useState(false);
    let page = 0;
    let isBottom = false;

    function format_date(date){
        let formatted_date = "";
        [8, 5, 0].map((index) => {
            if (index !== 0){
                formatted_date += date.substr(index,2)
                formatted_date += "/"
            } else {formatted_date += date.substr(index,4)}
            return 0;
        })
        return(formatted_date);
    }

    function fetch_sessions(){
        if (!isMobile){
            settings["pagination"]["pageIndex"] = page;
        } else {
            settings["pagination"]["pageIndex"] = page_mobile;
            setPage_mobile(page_mobile + 1);
        }
        const new_data = [];

        fetch(`${API_URL}/api/charge-transaction/query`, {
            method: "POST",
            body: JSON.stringify(settings),
            headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
        }).then((res) => {
            if(res.ok){
                return res.json();
            } else if (res.status === 400) {
                toast.error("Formato richiesta errato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 401) {
                toast.error("Sessione non valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                navigate("/login")
                localStorage.removeItem("token");
                return Promise.reject(res);
            } else if (res.status === 403) {
                toast.error("Non disponi delle autorizzazioni necessarie", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 404) {
                toast.error("Risorsa non trovata", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 500) {
                toast.error("Errore interno server", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            }
        })
        .then(({docs}) => {
            docs.forEach((i) => new_data.push(i));
            if (new_data.length !== 0) {
                setSessions((sessions) => [...sessions, ...new_data]);
            } else {
                !isMobile ? isBottom = true : setIsBottom_mobile(true);
            }
        }).catch((e) => console.log(e))
    }

    useEffect (() => {
        if(!token){
            navigate("/login")
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleResize);
        
        if (page_mobile === 0) {
            fetch_sessions();
        }

        if (!isMobile){
            scroll.current.addEventListener("scroll", () => {
                if ((scroll.current.scrollHeight - scroll.current.offsetHeight) < scroll.current.scrollTop+10) {
                    if (!isBottom){
                        page += 1;
                        fetch_sessions();
                    }
                }
            })
        }
    }, [])

    if (!isMobile){
        return(
            <div className="right_block">
                <div className="flex">
                    <p className="testo">Sessioni</p>
                </div>
                <div className="space"/>
                <div className="topBar">
                    <div className="bar_element" style={{"width": "5%"}}/>
                    <div className="bar_element" style={{"width": "19%"}}>UTENTE</div>
                    <div className="bar_element" style={{"width": "16%"}}>CHARGEPOINT</div>
                    <div className="bar_element" style={{"width": "12%"}}>INIZIO</div>
                    <div className="bar_element" style={{"width": "18%"}}>VALORE INIZIO</div>
                    <div className="bar_element" style={{"width": "12%"}}>FINE</div>
                    <div className="bar_element" style={{"width": "18%"}}>VALORE FINE</div>
                </div>
                <div className="lista_transazioni_sessioni" ref={scroll}>
                    {Object.values(sessions).map((data, index) => (
                        <div className="elementi_lista_transazioni" key={data._id}>
                            <div className="table_element" style={{"width": "5%", "textAlign": "right", "paddingLeft": "1rem"}}>{index+1}</div>
                            <div className="table_element" style={{"width": "19%"}}>{data.ocppTag.username}</div>
                            <div className="table_element" style={{"width": "16%"}}>{data.chargeboxId}</div>
                            <div className="table_element" style={{"width": "12%", "height": "3.2rem", "marginTop": "0.4rem"}}>
                                <div>{data.createdAt.substr(11, 5)}</div>
                                <div style={{"fontSize": "1rem"}}>{format_date(data.createdAt)}</div>
                            </div>
                            <div className="table_element" style={{"width": "18%"}}>{data.startValue}</div>
                            {data.closed === "true" ?
                                <div className="table_element" style={{"width": "12%", "height": "3.2rem", "marginTop": "0.4rem"}}>
                                    <div>{data.updatedAt.substr(11, 5)}</div>
                                    <div style={{"fontSize": "1rem"}}>{format_date(data.updatedAt)}</div>
                                </div>
                                : <div className="table_element" style={{"width": "12%", "height": "3.2rem", "marginTop": "0.5rem", "fontSize": "1.25rem"}}>
                                    <div>IN</div>
                                    <div>CORSO...</div>
                                </div>
                            }
                            <div className="table_element" style={{"width": "18%"}}>{data.value}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    } else {
        return(
            <ScrollSync>
                <div className="bottom_block_mobile">
                    <div className="flex">
                        <p className="testo">Sessioni</p>
                    </div>
                    <div className="flex_sessioni_transazioni" style={{"height": "calc(100% - 5rem)"}}>
                        <div className="left-block-table">
                            <div className="topBar" style={{"width": "4rem", "borderTopRightRadius": "0rem", "borderBottomRightRadius": "0rem"}}/>
                            <ScrollSyncPane group="vertical">
                                <div className="lista_transazioni_sessioni" style={{"height": "calc(100% - 4rem)", "borderTopRightRadius": "0rem", "borderBottomRightRadius": "0rem"}}> 
                                    {Object.values(sessions).map((data, index) => (
                                        <div className="elementi_lista_transazioni" key={index} style={{"marginTop": "0rem", "marginBottom": "1rem", "borderTopRightRadius": "0rem", "borderBottomRightRadius": "0rem"}}>
                                            <div className="table_element" style={{"width": "100%", "textAlign": "right", "paddingLeft": "1rem"}}>{index+1}</div>
                                        </div>
                                    ))}
                                </div>
                            </ScrollSyncPane>    
                        </div>
                        <div className="right-block-table">
                            <ScrollSyncPane group="horizontal">
                                <div className="topBar_container">
                                    <div className="topBar" style={{"width": "100rem", "overflowX": "scroll", "borderTopLeftRadius": "0rem", "borderBottomLeftRadius": "0rem"}}>
                                        <div className="bar_element" style={{"width": "19%"}}>UTENTE</div>
                                        <div className="bar_element" style={{"width": "16%"}}>CHARGEPOINT</div>
                                        <div className="bar_element" style={{"width": "12%"}}>INIZIO</div>
                                        <div className="bar_element" style={{"width": "18%"}}>VALORE INIZIO</div>
                                        <div className="bar_element" style={{"width": "12%"}}>FINE</div>
                                        <div className="bar_element" style={{"width": "18%"}}>VALORE FINE</div>
                                    </div>
                                </div>
                            </ScrollSyncPane>
                            <ScrollSyncPane group={["horizontal", "vertical"]}>
                                <div className="lista_transazioni_sessioni" ref={scroll} onScroll={(e) => {
                                        if ((e.target.scrollHeight - e.target.offsetHeight) < e.target.scrollTop+10) {
                                            if (!isBottom_mobile){
                                                fetch_sessions();
                                            }
                                        }
                                    }} style={{"height": "calc(100% - 4rem)", "overflowX": "scroll", "borderTopLeftRadius": "0rem", "borderBottomLeftRadius": "0rem"}}>
                                    {Object.values(sessions).map((data, index) => (
                                        <div className="elementi_lista_transazioni" key={data._id} style={{"marginTop": "0rem", "marginBottom": "1rem", "borderTopLeftRadius": "0rem", "borderBottomLeftRadius": "0rem", "width": "100rem"}}>
                                            <div className="table_element" style={{"width": "19%"}}>{data.ocppTag.username}</div>
                                            <div className="table_element" style={{"width": "16%"}}>{data.chargeboxId}</div>
                                            <div className="table_element" style={{"width": "12%", "height": "3.2rem", "marginTop": "0.4rem"}}>
                                                <div>{data.createdAt.substr(11, 5)}</div>
                                                <div style={{"fontSize": "1rem"}}>{format_date(data.createdAt)}</div>
                                            </div>
                                            <div className="table_element" style={{"width": "18%"}}>{data.startValue}</div>
                                            {data.closed === "true" ?
                                                <div className="table_element" style={{"width": "12%", "height": "3.2rem", "marginTop": "0.4rem"}}>
                                                    <div>{data.updatedAt.substr(11, 5)}</div>
                                                    <div style={{"fontSize": "1rem"}}>{format_date(data.updatedAt)}</div>
                                                </div>
                                                : <div className="table_element" style={{"width": "12%", "height": "3.2rem", "marginTop": "0.5rem", "fontSize": "1.25rem"}}>
                                                    <div>IN</div>
                                                    <div>CORSO...</div>
                                                </div>
                                            }
                                            <div className="table_element" style={{"width": "18%"}}>{data.value}</div>
                                        </div>
                                    ))}
                                </div>
                            </ScrollSyncPane>
                        </div>
                    </div>
                </div>
            </ScrollSync>
        )
    }
}

export default Sessioni;