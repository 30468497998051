import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css';
import Helmet from 'react-helmet';
import { toast } from "react-toastify";
import MediaQuery from 'react-responsive';

const API_URL = process.env.REACT_APP_API_URL;

function Login() {
    const [ values, setValues ] = useState({});
    const navigate = useNavigate();

    function submit(event){
        event.preventDefault();
        fetch(`${API_URL}/api/auth/login`, {
            method: "POST",
            body: JSON.stringify(values),
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if(res.ok){
                return(res.text())
            } else if (res.status === 400) {
                toast.error("Formato richiesta errato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 401) {
                toast.error("Username o password errati", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 403) {
                toast.error("Non disponi delle autorizzazioni necessarie", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 404) {
                toast.error("Risorsa non trovata", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            } else if (res.status === 500) {
                toast.error("Errore interno server", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 50,
                });
                return Promise.reject(res);
            }
        })
        .then((token) => {
            localStorage.setItem("token", token);
            navigate("/");
        }).catch((e) => {console.log(e)});
    }

    function handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        setValues({ ...values, [name]: value });
    }

    return(
        <div className="container">
            <Helmet bodyAttributes={{style: 'background-image: url("/assets/images/background_login.png")'}}/>
            <MediaQuery minWidth={1200}>
                <div className="login_field">
                    <div className="title">Accedi</div>
                    <form onSubmit={event => submit(event)} >
                        <div className="text">Username</div>
                        <div>
                            <input className="field" name = "usernameOrEmail" type="text" onChange={event => handleChange(event)}></input>
                        </div>
                        <div className="text">Password</div>
                        <div>
                            <input className="field" name = "password" type="password" onChange={event => handleChange(event)}></input>
                        </div>
                        <br></br>
                        <button className="accedi">Accedi</button>
                    </form>
                    <img src="/assets/images/logo_login.png" alt="Logo" className="logo_login" />
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1200}>
                <div className="login_field_mobile">
                    <div className="title">Accedi</div>
                    <form onSubmit={event => submit(event)} >
                        <div className="text">Username</div>
                        <div>
                            <input className="field_mobile" name = "usernameOrEmail" type="text" onChange={event => handleChange(event)}></input>
                        </div>
                        <div className="text">Password</div>
                        <div>
                            <input className="field_mobile" name = "password" type="password" onChange={event => handleChange(event)}></input>
                        </div>
                        <br></br>
                        <button className="accedi_mobile">Accedi</button>
                    </form>
                    <img src="/assets/images/logo_login.png" alt="Logo" className="logo_login_mobile" />
                </div>
            </MediaQuery>
        </div>
    );
}

export default Login;