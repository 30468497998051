import React from "react";
import Stazioni from "./Stazioni";
import Sessioni from "./Sessioni";
import Transazioni from "./Transazioni";

function RenderPagina(props){
    if (props.pagina === 0){
        return(
            <Stazioni isMobile = {props.isMobile}/>
        )
    } else if (props.pagina === 1) {
        return(
            <Sessioni isMobile = {props.isMobile}/>
        )
    } else if (props.pagina === 2) {
        return(
            <Transazioni isMobile = {props.isMobile}/>
        )
    }
}

export default RenderPagina;