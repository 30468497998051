import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RenderStazioni from "./RenderStazioni";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

function ModCaricatori(props){
    const [isMobile, setIsMobile] = useState(props.isMobile);
    const navigate = useNavigate();
    const [back, setBack] = useState(false);
    const token = localStorage.getItem('token');
    const [fields, setFields] = useState({
        "chargePointSerialNumber": props.chargerData["chargePointSerialNumber"] === undefined ? "" : props.chargerData["chargePointSerialNumber"],
        "code": props.chargerData["code"] === undefined ? "" : props.chargerData["code"],
        "plugType": props.chargerData["plugType"] === undefined ? "" : props.chargerData["plugType"],
        "cost": props.chargerData["cost"] === undefined ? "" : props.chargerData["cost"],
        "station": props.idStation,
        "status": "Available"
    });
    const idCharger = props.chargerData["_id"];
    const types = ["Type 1", "Type 2", "CCS", "CHAdeMO"]
    const ind = types.indexOf(fields["plugType"])
    const [color_plug, setColor_plug] = useState({ ...["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"], [ind]: "#F2F2F2" });

    useEffect(() => {
        if(!token){
            navigate("/login")
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleResize);
    },[])

    useEffect(() => {
        setFields({ ...fields, "station": props.idStation })
    }, [props.idStation])

    function fields_catch(data_fields){
        const name = data_fields.target.id;
        if (name==="cost"){
            if (data_fields.target.value !== ""){
                const value = parseFloat(data_fields.target.value);
                setFields({ ...fields, "cost": value });
            } else {
                setFields({ ...fields, "cost": "" });
            }
        } else {
            const value = data_fields.target.value;
            setFields({ ...fields, [name]: value });
        }
    }

    function handleClick(type){
        const ind = types.indexOf(type)
        setColor_plug({ ...["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"], [ind]: "#F2F2F2" });
        setFields({ ...fields, "plugType": type })
    }

    function deleteCharger(){
        if (props.chargerData === "New"){
            props.onSubmit();
        } else {
            fetch(`${API_URL}/api/station-charger/${idCharger}`, {
                method: "DELETE",
                headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
            }).then((res) => {
                if(res.ok){
                    return res.json();
                } else if (res.status === 400) {
                    toast.error("Formato richiesta errato", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 50,
                    });
                    return Promise.reject(res);
                } else if (res.status === 401) {
                    toast.error("Sessione non valida", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 50,
                    });
                    navigate("/login")
                    localStorage.removeItem("token");
                    return Promise.reject(res);
                } else if (res.status === 403) {
                    toast.error("Non disponi delle autorizzazioni necessarie", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 50,
                    });
                    return Promise.reject(res);
                } else if (res.status === 404) {
                    toast.error("Risorsa non trovata", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 50,
                    });
                    return Promise.reject(res);
                } else if (res.status === 500) {
                    toast.error("Errore interno server", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 50,
                    });
                    return Promise.reject(res);
                }
            })
            .then((res) => {
                props.changeView();
                setBack(true);
                console.log(res)
            }).catch((e) => console.log(e))
        }
    }

    function fields_controller(){
        if (Object.values(fields).includes("") || Number.isNaN(fields.cost)){
            return(true)
        }
    }

    function saveCharger(){
        if (!fields_controller()){
            if (props.chargerData === "New"){
                fetch(`${API_URL}/api/station-charger`, {
                    method: "POST",
                    body: JSON.stringify(fields),
                    headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    } else if (res.status === 400) {
                        toast.error("Formato richiesta errato", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 401) {
                        toast.error("Sessione non valida", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        navigate("/login")
                        localStorage.removeItem("token");
                        return Promise.reject(res);
                    } else if (res.status === 403) {
                        toast.error("Non disponi delle autorizzazioni necessarie", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 404) {
                        toast.error("Risorsa non trovata", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 409) {
                        toast.error("Il codice deve essere univoco", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 500) {
                        toast.error("Errore interno server", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    }
                })
                .then((res) => {
                    props.changeView();
                    props.onSubmit();
                    console.log(res)
                }).catch((e) => console.log(e))
            } else {
                fetch(`${API_URL}/api/station-charger/${idCharger}`, {
                    method: "PUT",
                    body: JSON.stringify(fields),
                    headers: {"Authorization": token, "Content-Type": "application/json", "accept": "application/json"}
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    } else if (res.status === 400) {
                        toast.error("Formato richiesta errato", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 401) {
                        toast.error("Sessione non valida", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        navigate("/login")
                        localStorage.removeItem("token");
                        return Promise.reject(res);
                    } else if (res.status === 403) {
                        toast.error("Non disponi delle autorizzazioni necessarie", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 404) {
                        toast.error("Risorsa non trovata", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 409) {
                        toast.error("Il codice deve essere univoco", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    } else if (res.status === 500) {
                        toast.error("Errore interno server", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 50,
                        });
                        return Promise.reject(res);
                    }
                })
                    .then((res) => {
                        props.changeView();
                        setBack(true);
                        console.log(res)
                    }).catch((e) => console.log(e))
            }
        } else {
            toast.error("Completa tutti i campi", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 50,
            });
        }
    }

    if (!back){
        if (!isMobile){
            return(
                <div className="mod_charger">
                    <div className="flex">
                        <div>
                            <div className="testo" style={{"fontSize": "1.4rem", "color": "black"}}>Codice</div>
                            <input type="number" id="code"  value={fields.code} className="fields_generali" style={{"width": "calc(50vw - 18.5rem)", "height": "3.8rem", "textDecoration": "none", "backgroundColor": "#F2F2F2"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                        </div>
                        <div>
                            <div className="testo" style={{"fontSize": "1.4rem", "color": "black"}}>Numero seriale</div>
                            <input type="number" id="chargePointSerialNumber"  value={fields.chargePointSerialNumber} className="fields_generali" style={{"width": "calc(50vw - 18.5rem)", "height": "3.8rem", "textDecoration": "none", "backgroundColor": "#F2F2F2"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                        </div>
                    </div>
                    <div className="flex" style={{"paddingTop": "2rem", "height": "3.5rem"}}>
                        <div>
                            <div className="testo" style={{"fontSize": "1.4rem", "color": "black"}}>Plug</div>
                            <div className="plugs_container">
                                <button className="plug_buttons" style={{"backgroundColor": color_plug[0]}} onClick={() => handleClick("Type 1")}>
                                    <img className="type" alt="type1" src="/assets/images/type1.png"/>
                                </button>
                                <button className="plug_buttons" style={{"backgroundColor": color_plug[1]}} onClick={() => handleClick("Type 2")}>
                                    <img className="type" alt="type2" src="/assets/images/type2.png"/>
                                </button>
                                <button className="plug_buttons" style={{"backgroundColor": color_plug[2]}} onClick={() => handleClick("CCS")}>
                                    <img className="type" alt="ccs" src="/assets/images/ccs.png"/>
                                </button>
                                <button className="plug_buttons" style={{"backgroundColor": color_plug[3]}} onClick={() => handleClick("CHAdeMO")}>
                                    <img className="type" alt="chademo" src="/assets/images/chademo.png"/>
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className="testo" style={{"fontSize": "1.4rem", "color": "black"}}>Costo</div>
                            <div style={{"position": "relative"}}>
                                <div className="testo" style={{"position": "absolute", "paddingTop": "0rem", "paddingLeft": "calc(50vw - 25rem)", "fontSize": "1.4rem", "color": "black"}}>€/kWh</div>
                                <input type="number" id="cost"  value={fields.cost} className="field_mod" style={{"width": "calc(50vw - 18.5rem)", "height": "3.8rem", "textDecoration": "none", "backgroundColor": "#F2F2F2"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div style={{"marginTop": "5rem", "height": "1rem", "width": "calc(100% - 32rem)"}}/>
                        <button className="elimina" onClick={() => deleteCharger()}/>
                        <button className="conferma" onClick={() => saveCharger()}>Conferma</button>
                    </div>
                    <div style={{"height": "2.5rem"}}/>
                </div>
            )
        } else {
            return(
                <div className="mod_charger" style={{"paddingLeft": "0.5rem", "paddingRight": "0.5rem", "paddingBottom": "0.5rem"}}>
                    <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem", "color": "black"}}>Codice</div>
                    <input type="number" id="code"  value={fields.code} className="field_mobile" style={{"textDecoration": "none", "backgroundColor": "#F2F2F2", "width": "calc(100% - 1.5rem)"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                    <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem", "color": "black"}}>Numero seriale</div>
                    <input type="number" id="chargePointSerialNumber"  value={fields.chargePointSerialNumber} className="field_mobile" style={{"textDecoration": "none", "backgroundColor": "#F2F2F2", "width": "calc(100% - 1.5rem)"}} spellCheck="false" onChange={data => fields_catch(data)}/>
                    <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem", "color": "black", "width": "calc(100% - 1.5rem)"}}>Plug</div>
                    <div className="plugs_container">
                        <button className="plug_buttons" style={{"backgroundColor": color_plug[0]}} onClick={() => handleClick("Type 1")}>
                            <img className="type" alt="type1" src="/assets/images/type1.png"/>
                        </button>
                        <button className="plug_buttons" style={{"backgroundColor": color_plug[1]}} onClick={() => handleClick("Type 2")}>
                            <img className="type" alt="type2" src="/assets/images/type2.png"/>
                        </button>
                        <button className="plug_buttons" style={{"backgroundColor": color_plug[2]}} onClick={() => handleClick("CCS")}>
                            <img className="type" alt="ccs" src="/assets/images/ccs.png"/>
                        </button>
                        <button className="plug_buttons" style={{"backgroundColor": color_plug[3]}} onClick={() => handleClick("CHAdeMO")}>
                            <img className="type" alt="chademo" src="/assets/images/chademo.png"/>
                        </button>
                    </div>
                    <div>
                        <div className="testo_mobile" style={{"fontSize": "1rem", "paddingLeft": "0.5rem", "color": "black", "width": "calc(100% - 1.5rem)"}}>Costo</div>
                        <div style={{"position": "relative"}}>
                            <div className="testo" style={{"position": "absolute", "paddingTop": "0rem", "paddingLeft": "calc(100% - 10rem)", "fontSize": "1.4rem", "color": "black"}}>€/kWh</div>
                            <input type="number" id="cost" className="field_mobile" style={{"textDecoration": "none", "backgroundColor": "#F2F2F2", "width": "calc(100% - 1.5rem)"}} value={fields.cost} spellCheck="false" onChange={data => fields_catch(data)}/>
                        </div>
                    </div>
                    <div className="flex_mobile">
                        <div style={{"width": "calc(100% - 16rem)"}}/>
                        <button className="elimina_mobile" onClick={() => deleteCharger()}/>
                        <button className="conferma_mobile" onClick={() => saveCharger()}>Conferma</button>
                    </div>
                    <div style={{"height": "1rem"}}/>
                </div>
            )
        }
    } else if(back){
        return(<RenderStazioni dataStation={props.dataStation} idStation={props.idStation} isMobile = {isMobile}/>)
    }
}

export default ModCaricatori;